import { MantineColorsTuple } from '@mantine/core';

export const BrandColorYellow = `#ffba00`;

export const BrandColorBlack = `#303030`;

export const BrandColorYellowMantine: MantineColorsTuple = [
  `#ffd78d`,
  `#ffd077`,
  `#ffc95f`,
  `#ffc241`,
  BrandColorYellow,
  `#dea100`,
  `#be8900`,
  `#9e7200`,
  `#805c00`,
  `#634600`,
];

export const BrandColorBlackMantine: MantineColorsTuple = [
  `#7c7c7c`,
  `#686868`,
  `#545454`,
  `#424242`,
  BrandColorBlack,
  `#282828`,
  `#212121`,
  `#1a1a1a`,
  `#131313`,
  `#0c0c0c`,
];
