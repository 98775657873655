import { createTheme } from '@mantine/core';
import {
  BrandColorBlack,
  BrandColorBlackMantine,
  BrandColorYellowMantine,
} from './style';

export const theme = createTheme({
  /* Put your mantine theme override here */
  colors: {
    'brand-color-yellow': BrandColorYellowMantine,
    'brand-color-black': BrandColorBlackMantine,
  },
  black: BrandColorBlack,
  fontFamily: `Montserrat`,
  headings: {
    fontFamily: `Jost`,
  },
});
